<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="addDealDialog" max-width="600px">
      <v-card>
        <v-card-title>Deal</v-card-title>
        <v-col cols="12">
          <v-select
            placeholder="Select Deal"
            color="#30B868"
            v-model="deal.dealType"
            :items="deals"
            item-color="#30B868"
            outlined
            dense
            clearable
            hint="You need min 3 products in deal to appear in listing"
            persistent-hint
          >
            <template v-slot:item="{ item }">
              <span class="text-left">{{ item }}</span>
            </template>
          </v-select>
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="addDealDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            :disabled="!deal.dealType ? true : false"
            @click="addDeal"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 18px;
              "
              >Do you want to delete this product ?</span
            >
          </v-flex>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 14px;
              "
              >{{ storage.productname }}</span
            >
          </v-flex>
          <v-flex xs6 px-2>
            <v-btn
              tile
              block
              depressed
              dark
              color="#30B868"
              @click="deleteProduct"
            >
              <span style="font-family: poppinsregular; font-size: 14px">
                Yes
              </span>
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1BD93B"
              @click="deleteDialogue = false"
            >
              <span style="font-family: poppinsregular; font-size: 14px">
                No
              </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap py-2>
      <v-flex xs12 sm12 md12 lg6>
        <v-layout wrap justify-start>
          <v-flex xs12 sm3 md3 lg2 xl2 pr-md-2>
            <v-img
              :src="mediaUURL + storage.photos[0]"
              aspect-ratio="1"
              contain
              height="100px"
              :alt="storage.productname"
            ></v-img>
          </v-flex>
          <v-flex xs12 sm9 md9 lg9 xl9>
            <v-layout wrap justify-center fill-height>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex lg12 text-left>
                    <router-link :to="'/Product/' + storage._id">
                      <span
                        style="
                          font-family: opensanslight;
                          font-size: 16px;
                          color: #000000;
                        "
                      >
                        {{ storage.productname }}
                      </span>
                    </router-link>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 align-self-end>
                <v-layout
                  wrap
                  justify-start
                  v-for="(item, i) in storage.size"
                  :key="i"
                >
                  <v-flex xs6 sm3 md3 lg3 xl3>
                    <v-layout wrap v-if="item.size">
                      <v-flex text-left xs12>
                        <span
                          style="
                            color: #8d8d8d;
                            font-family: opensanslight;
                            font-size: 14px;
                          "
                        >
                          Variant
                        </span>
                      </v-flex>
                      <v-flex text-left xs12>
                        <span
                          style="font-family: opensansbold; font-size: 20px"
                        >
                          {{ item.size }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6 sm2 md2 lg2 xl2>
                    <v-layout wrap>
                      <v-flex text-left lg12>
                        <span
                          style="
                            color: #8d8d8d;
                            font-family: opensanslight;
                            font-size: 14px;
                          "
                          >Price</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex text-left lg12>
                        <span
                          style="
                            font-family: opensansbold;
                            color: #3bc17a;
                            font-size: 20px;
                          "
                          >{{ item.price }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6 sm2 md2 lg2 xl2>
                    <v-layout wrap>
                      <v-flex text-left lg12>
                        <span
                          style="
                            color: #8d8d8d;
                            font-family: opensanslight;
                            font-size: 14px;
                          "
                          >Offer Price</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex text-left lg12>
                        <span
                          style="
                            font-family: opensansbold;
                            color: #3bc17a;
                            font-size: 20px;
                          "
                          >{{ item.offerPrice }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs6
                    sm3
                    md3
                    lg3
                    xl3
                    v-for="(list, l) in item.stock"
                    :key="l"
                  >
                    <v-layout wrap>
                      <v-flex xs6>
                        <v-layout wrap>
                          <v-flex text-left lg12>
                            <span
                              style="
                                color: #8d8d8d;
                                font-family: opensanslight;
                                font-size: 14px;
                              "
                              >Stock</span
                            >
                          </v-flex>
                        </v-layout>
                        <v-layout wrap>
                          <v-flex text-left lg12>
                            <span
                              style="
                                font-family: opensansbold;
                                color: black;
                                font-size: 20px;
                              "
                              >{{ list.stock }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs6 v-if="list.color">
                        <v-layout wrap>
                          <v-flex text-left lg12>
                            <span
                              style="
                                color: #8d8d8d;
                                font-family: opensanslight;
                                font-size: 14px;
                              "
                              >Colour</span
                            >
                          </v-flex>
                        </v-layout>
                        <v-layout wrap>
                          <v-flex text-left lg12>
                            <v-card
                              outlined
                              rounded="30px"
                              class="colorselector"
                              width="30px"
                              :color="list.color ? '#ffebeb' : ''"
                            >
                              <v-layout py-2 wrap align-content-center>
                                <v-flex xs12 text-center>
                                  <v-avatar :color="list.color" size="15" />
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs4 text-left align-self-end>
                    <v-layout wrap>
                      <v-flex text-left xs12>
                        <span
                          style="
                            color: #8d8d8d;
                            font-family: opensanslight;
                            font-size: 14px;
                          "
                        >
                          Offer
                        </span>
                      </v-flex>
                      <v-flex text-left xs12>
                        <span
                          style="
                            font-family: opensansbold;
                            color: #3bc17a;
                            font-size: 20px;
                          "
                        >
                          <span>
                            {{ item.offerPercentage.toFixed() }}%
                          </span>
                          <!-- <span v-else>
                            {{ item.offerPercentage }}%
                          </span> -->
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex text-left xs1 text-center hidden-md-and-down>
            <v-divider vertical></v-divider>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex text-left xs12 md12 lg6>
        <v-layout wrap justify-space-between fill-height>
          <v-flex sm3 md3 lg3 xl3>
            <v-layout wrap>
              <v-flex xs6 sm12>
                <span
                  style="
                    color: #8d8d8d;
                    font-family: opensanslight;
                    font-size: 14px;
                  "
                >
                  Item added on
                </span>
              </v-flex>
              <v-flex xs6 sm12>
                <span
                  style="
                    font-family: opensanssemibold;
                    font-size: 15px;
                    color: #000000;
                  "
                >
                  {{ formatDate(storage.created_at).slice(0, 11) }}
                  <br />
                  {{ formatDate(storage.created_at).slice(11, 20) }}
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex sm3 md3 lg3 xl3>
            <v-layout wrap fill-height>
              <v-flex xs6 sm12>
                <span
                  style="
                    color: #8d8d8d;
                    font-family: opensanslight;
                    font-size: 14px;
                  "
                >
                  Available Stock
                </span>
              </v-flex>
              <v-flex xs6 sm12 text-left align-self-start>
                <span style="font-family: opensansbold; font-size: 20px">
                  {{ storage.stockquantity }}
                </span>
              </v-flex>
              <v-flex xs6 sm12 align-self-end>
                <v-btn
                  dark
                  small
                  tile
                  depressed
                  :ripple="false"
                  :color="storage.stockquantity>0 ? '#FFBC6E' : '#EB6359'"
                  style="text-transform: none"
                >
                  <span
                    style="font-family: poppinsregular; font-size: 14px"
                    v-if="storage.stockquantity>0"
                  >
                    <v-icon>mdi-check</v-icon>
                    Available
                  </span>
                  <span
                    style="font-family: poppinsregular; font-size: 14px"
                    v-else
                  >
                    <v-icon>mdi-close</v-icon>
                    Out of Stock
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex sm6 md5 lg5 xl5 text-left>
            <v-layout wrap justify-space-between fill-height>
              <!-- <v-flex xs12 xl4 align-self-start>
                <v-layout wrap justify-start>
                  <v-flex xs12 text-left>
                    <span
                      style="
                        color: #8d8d8d;
                        font-family: opensanslight;
                        font-size: 14px;
                      "
                    >
                      Offer Status
                    </span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span
                      style="
                        font-family: opensansbold;
                        color: #3bc17a;
                        font-size: 14px;
                      "
                    >
                      <span v-if="storage.offerapproved"> Approved </span>
                      <span v-else style="color: #30B868"> Pending </span>
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex> -->
              <v-flex xs12 align-self-end>
                <v-layout wrap justify-center fill-height>
                  <!-- <v-flex xs4 text-left align-self-end>
                    <v-layout wrap>
                      <v-flex text-left xs12>
                        <span
                          style="
                            color: #8d8d8d;
                            font-family: opensanslight;
                            font-size: 14px;
                          "
                        >
                          Offer
                        </span>
                      </v-flex>
                      <v-flex text-left xs12>
                        <span
                          style="
                            font-family: opensansbold;
                            color: #3bc17a;
                            font-size: 20px;
                          "
                        >
                          <span v-if="storage.offerapproved">
                            {{ storage.offerpercentage }}%
                          </span>
                          <span v-else>
                            {{ storage.offersetpercentage }}%
                          </span>
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex> -->
                  <!-- <v-flex xs4 text-left align-self-end>
                    <v-layout wrap justify-center>
                      <v-flex text-left xs12>
                        <span
                          style="
                            color: #8d8d8d;
                            font-family: opensanslight;
                            font-size: 14px;
                          "
                        >
                          Offer Price
                        </span>
                      </v-flex>
                      <v-flex text-lwft xs12>
                        <span
                          style="
                            font-family: opensansbold;
                            color: #3bc17a;
                            font-size: 20px;
                          "
                        >
                          {{ storage.price }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex> -->
                  <v-flex xs4 text-center align-self-end>
                    <v-btn
                      tile
                      block
                      small
                      outlined
                      color="warning"
                      style="text-transform: none"
                      :to="'/Offers/Add/' + storage._id"
                    >
                      <span
                        style="
                          color: #000;
                          font-family: poppinsregular;
                          font-size: 14px;
                        "
                      >
                        <v-icon left>mdi-pencil</v-icon>
                        Edit
                      </span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      timeout: 5000,
      msg: "",
      ServerError: false,
      showSnackBar: false,
      statusLoading: false,
      deleteDialogue: false,
      addDealDialog: false,
      deals: ["Buy 2 Get 1 Free", "Buy 3 above 5000 INR at 3000 INR"],
      deal: {
        dealType: null,
      },
    };
  },
  methods: {
    changeProductStatus(item) {
      this.statusLoading = true;

      axios({
        method: "POST",
        url: "/product/status",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: item._id,
          status: item.status,
        },
      })
        .then((response) => {
          this.statusLoading = false;
          if (response.data.status) {
            this.msg = "Product Status Changed to " + item.status;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.statusLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteProduct() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/delete",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.storage._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.deleteDialogue = false;
            this.$emit("stepper", {
              getData: true,
            });
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    addDeal() {
      this.appLoading = true;
      var deal = {};
      deal["productid"] = this.storage._id;
      deal["dealtype"] = this.deal.dealType;
      axios({
        method: "POST",
        url: "/home/deal/add",
        data: deal,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.$router.push("/Product/Updated/" + this.storage._id);
            this.msg = response.data.msg;
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>

<style>
div.vertical-line {
  width: 1px; /* Line width */
  background-color: #8d8d8d; /* Line color */
  height: 100%; /* Override in-line if you want specific height. */
  float: left; /* Causes the line to float to left of content. 
        You can instead use position:absolute or display:inline-block
        if this fits better with your design */
}
.statusKey.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
</style>
